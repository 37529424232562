import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { ContactForm, Testimonials } from "../../components/index"
import {
  Container,
  Wrapper,
  Column,
  Text,
  Button,
  SEOTitle,
  Row,
} from "../../theme"
import { Responsive, Colors } from "../../theme/styles"
import { CallToAction } from "../../components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const StyledImg = styled(BgImage)`
  ${Responsive.sm`
  height: ${props => (props.arrow ? "100%" : "225px")};
  width: ${props => (props.arrow ? "100%" : "auto")};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  `}
  ${Responsive.md`
  height: ${props => (props.arrow ? "400px" : "175px")};
   `}
   ${Responsive.lg`
   height: ${props => (props.arrow ? "500px" : "175px")};
    `}
    ${Responsive.xlg`
    height: ${props => (props.arrow ? "400px" : "175px")};
     `}
`

// const TestimonialBox = styled.div`
//   display: flex;
//   width: 100%;
//   height: 246px;
//   background: ${Colors.darkBlue};
//   border-radius: 7px;
//   padding: 30px;
// `

// const ServiceCard = styled.div`
//   ${Responsive.sm`
// width: 100%;
// height: 100%;
// margin-top: 30px;
// margin-bottom: 30px;
// font-family: ${Fonts.montserrat};
// cursor: pointer;
// margin-right: ${props => (props.marginRight ? "10px" : "0")};
// // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
// // transition: 0.3s;
// `}
//   ${Responsive.lg`
// margin-top: 0px;
// margin-bottom: 0px;
// `}
// `

// const MarketCard = styled.div`
//   ${Responsive.sm`
// width: 100%;
// height: 100%;
// margin-top: 30px;
// background: ${Colors.white};
// padding: 55px;
// margin-bottom: 30px;
// font-family: ${Fonts.montserrat};
// margin-right: ${props => (props.marginRight ? "10px" : "0")};
// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
// border-radius: 5px;
// transition: 0.3s;
// margin-left: 0;
// `}
//   ${Responsive.lg`
// margin-top: 0px;
// margin-bottom: 0px;
// margin-left: 40px;
// `}
// `

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 320px);
gap: 45px;
width: 100%;
justify-content: center;
padding-bottom: 75px;
max-width: 100%;
`}
  ${Responsive.md`
display: grid;
grid-template-columns: repeat(auto-fit, 600px);
gap: 15px;
max-width: 1400px;
margin: 0px auto 0 auto;
justify-content: center;
`}
  ${Responsive.lg`
  display: grid;
  grid-template-columns: repeat(auto-fit,55% 45%);
  gap: 15px;
  max-width: 1400px;
  margin: 0px auto 0 auto;
  justify-content: center;
`}
  ${Responsive.xlg`
display: grid;
grid-template-columns: repeat(auto-fit,60% 40%);
gap: 45px;
max-width: 1400px;
margin: 0px auto 0 auto;
justify-content: center;
`}
`

// const FormRow = styled(Row)`
//   width: 100%;
//   justify-content: space-between;
// `

// const StyledLi = styled(Text)`
//   margin-top: 10px;
//   font-size: 20px;
//   color: inherit;
// `

const StyledText = styled(Text)`
  text-align: left;
  font-weight: ${props => (props.bold ? "bold" : "400")};
  margin-bottom: ${props => (props.marginbottom ? "10px" : 0)};
`

// const TargetBackground = styled(Column)`
//   background-color: ${Colors.darkBlue};
// `

// const StyledRow = styled(Row)`
//   ${Responsive.sm`
// width: 100%;
// margin: 50px 0;
// justify-content: center;
// flex-direction: column;
// align-items: center;
// `}
//   ${Responsive.lg`
// justify-content: space-between;
// flex-direction: row;
// align-items: flex-start;
// `}
// `
// const StyledColumn = styled(Column)`
//   ${Responsive.sm`
// width: 100%;
// `}
//   ${Responsive.lg`
// width: 50%;
// `}
// `

const Dot = styled.div`
  border-radius: 50%;
  width: 29px;
  height: 29px;
  background-color: ${Colors.lightBlue};
`

const PointGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
gap: 15px;
width: 100%;
margin-top: 40px;
justify-content: center;
`}
  ${Responsive.md`
grid-template-columns: repeat(auto-fit, 250px);
margin-bottom: 40px;
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 225px);
gap: 35px;
width: 100%;
margin-top: 60px;
justify-content: flex-start;
margin-bottom: 0px;
`}
  ${Responsive.xlg`
display: grid;
grid-template-columns: repeat(auto-fit, 325px);
gap: 35px;
width: 100%;
margin-top: 60px;
justify-content: flex-start;
`}
`

const TitleRow = styled(Row)`
  ${Responsive.sm`
justify-content: flex-start;
margin-right: 0px;
`}
  ${Responsive.lg`
justify-content: flex-start;
margin-right: 150px;
`}
`

// const ClientGrid = styled.div`
//   ${Responsive.xlg`
// display: grid;
// grid-template-columns: repeat(auto-fit, 125px);
// gap: 35px;
// width: 100%;
// justify-content: center;

// `}
// `

const BannerGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(1, 1fr);
max-width: 820px;
margin: 0 auto 0 auto;
align-items: center;
justify-content: flex-start;
`}
  ${Responsive.md`
display: grid;
grid-template-columns: repeat(2, 35% 65%);
max-width: 820px;
margin: 0 auto 0 auto;
align-items: center;
justify-content: flex-start;
`}
  ${Responsive.lg`
grid-template-columns: repeat(2, 40% 60%);
max-width: 920px;
`}
  ${Responsive.xlg`
grid-template-columns: repeat(2, 30% 70%);
max-width: 1400px;
`}
`

const ProcessGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(1, 1fr);
max-width: 820px;
margin-top: 50px;
align-items: center;
grid-gap: 35px;
justify-content: flex-start;
margin-bottom: 0;
`}
  ${Responsive.md`
display: grid;
grid-template-columns: repeat(2, 50%);
margin-top: 75px;
align-items: center;
justify-content: center;
margin-bottom: 50px;
`}
  ${Responsive.lg`
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
grid-gap: 55px;
margin-bottom: 0;
`}
  ${Responsive.xlg`
grid-template-columns: repeat(auto-fit, 380px);
max-width: 1400px;
margin-bottom: 0;
`}
`

const ProfessionRow = styled(Row)`
  ${Responsive.sm`
width: 100%;
justify-content: space-evenly;
margin: ${props => (props.margin ? "20px 0 0 0px" : "30px 0px 0 0")};
`}
  ${Responsive.md`
width: 100%;
justify-content: space-evenly;
margin: ${props => (props.margin ? "30px 0 0 0px" : "0 0px 0 0")};
`}
  ${Responsive.lg`
width: 100%;
justify-content: space-evenly;
margin: ${props => (props.margin ? "30px 0 0 75px" : "0 100px 0 0")};
`}
  ${Responsive.xlg`
    width: 100%;
    justify-content: space-evenly;
    margin: ${props => (props.margin ? "30px 0 0 125px" : "0 50px 0 0")};
  `}
`

const FinalColumn = styled(Column)`
  ${Responsive.sm`
    align-items: flex-start;
    margin: 0;
  `}
  ${Responsive.lg`
    align-items: flex-start;
    margin: 0 0 0 50px;
`}
`
const data = [
  {
    service: "Developed For You",
    list1:
      "Don't stress. We'll take care of the design and development and deliver it within 10 days",
  },
  {
    service: "10 Day Guarantee",
    list1:
      "Receive your website solution within 10 days or get 1/2 off the final price",
  },
  {
    service: "Affordable",
    list1: "You'll have a new website making you money for just $600",
  },
  {
    service: "One Page Solution",
    list1:
      "Our one page website solution keeps your content concise and performance high leading to more conversions",
  },
]

// const processData = [
//   {
//     title: "Introductory Meeting",
//     text: "You'll tell us all about your business so we can deliver a product perfect for you.",
//   },
//   {
//     title: "Goal Setting",
//     text: "Every website should have an intended goal and purpose. We'll discuss what actions we want your visitors to take while on your website.",
//   },
//   {
//     title: "Goal Setting",
//     text: "Every website should have an intended goal and purpose. We'll discuss what actions we want your visitors to take while on your website.",
//   },
// ]

const SinglePageWebsite = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "landingbarimage.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      arrow: file(relativePath: { eq: "arrow3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const bar = getImage(bgImage.background.childImageSharp.gatsbyImageData)
  const arrow = getImage(bgImage.arrow.childImageSharp.gatsbyImageData)

  return (
    <Layout
      type="onepage"
      title="Relieve Your Stress"
      subtitle="Get your website built in just 10 days for only $600"
    >
      <Wrapper>
        <Container>
          <Column>
            <Grid>
              <Column>
                <TitleRow>
                  <SEOTitle black margin="0 auto 0 auto">
                    Get a quality, affordable website delivered in just 10 days.
                  </SEOTitle>
                </TitleRow>
                <PointGrid>
                  {data.map((key, index) => {
                    return (
                      <Row key={index} width="100%">
                        <Column margin="0 10px 0 0" alignitems="flex-start">
                          <Dot />
                        </Column>
                        <Column alignitems="flex-start">
                          <StyledText bold marginbottom>
                            {key.service}
                          </StyledText>
                          <StyledText>{key.list1}</StyledText>
                        </Column>
                      </Row>
                    )
                  })}
                </PointGrid>
              </Column>
              <Column>
                <ContactForm width="100%" />
              </Column>
            </Grid>
          </Column>
        </Container>
        <StyledImg image={bar}>
          <BannerGrid>
            <Column width="100%">
              <SEOTitle white>
                A stress free website solution perfect for:
              </SEOTitle>
            </Column>
            <Column>
              <ProfessionRow>
                <Text white fontweight="bold">
                  Coaches
                </Text>
                <Text white fontweight="bold">
                  Consultants
                </Text>
                <Text white fontweight="bold">
                  Freelancers
                </Text>
                <Text white fontweight="bold">
                  Gyms
                </Text>
              </ProfessionRow>
              <ProfessionRow margin>
                <Text white fontweight="bold">
                  Cafes
                </Text>
                <Text white fontweight="bold">
                  Real Estate Agents
                </Text>
                <Text white fontweight="bold">
                  Personal Trainers
                </Text>
              </ProfessionRow>
            </Column>
          </BannerGrid>
        </StyledImg>
        <Column margin="50px 0 0 0">
          <SEOTitle>Our Process</SEOTitle>
          <StyledImg image={arrow} arrow>
            <Container>
              <ProcessGrid>
                <Column alignitems="flex-start">
                  <Column alignitems="flex-start">
                    <StyledText bold marginbottom>
                      Introductory Meeting
                    </StyledText>
                    <StyledText>
                      You’ll tell us all about your business so we can deliver a
                      product perfect for you.
                    </StyledText>
                  </Column>
                  <Column alignitems="flex-start" margin="30px 0 0 0">
                    <StyledText bold marginbottom>
                      Goal Setting
                    </StyledText>
                    <StyledText>
                      Every website should have an intended goal and purpose.
                      We’ll discuss what actions we want your visitors to take
                      while on your website.
                    </StyledText>
                  </Column>
                </Column>
                <Column alignitems="flex-start" height="100%">
                  <Column alignitems="flex-start">
                    <StyledText bold marginbottom>
                      Design + Development
                    </StyledText>
                    <StyledText>
                      You can relax knowing we’re taking care of your website
                      design and development.
                    </StyledText>
                  </Column>
                  <Column alignitems="flex-start" margin="30px 0 0 0">
                    <StyledText bold marginbottom>
                      Delivery of Product
                    </StyledText>
                    <StyledText>
                      The product will be delivered in just 10 days! Work will
                      begin the day after you set your goals.
                    </StyledText>
                  </Column>
                </Column>
                <FinalColumn>
                  <StyledText bold marginbottom>
                    Start Making Money
                  </StyledText>
                  <StyledText>
                    You have your new website and now you’re ready to capture
                    leads, grow your business, and make more money!
                  </StyledText>
                </FinalColumn>
              </ProcessGrid>
            </Container>
          </StyledImg>
          <Row justifycontent="center" width="100%" margin="30px auto 0 auto">
            <Link to="/contact">
              <Button background={Colors.lightBlue}>Fill Out Form</Button>
            </Link>
          </Row>
        </Column>
        <Testimonials type="landingpage" />
      </Wrapper>
      <CallToAction type="landingpage" />
    </Layout>
  )
}

export default SinglePageWebsite
// <Row width="100%" justifycontent="space-between">
//   <Column width="80%" margin="0 50px 0 0">
//     <Subtitle white>
//       A stress free website solution perfect for:
//     </Subtitle>
//   </Column>
//   <ClientGrid>
//     <Text white>Coaches</Text>
//     <Text white>Consultants</Text>
//     <Text white>Freelancers</Text>
//     <Text white>Gyms</Text>
//   </ClientGrid>
//   <ClientGrid>
//     <Text white>Coaches</Text>
//     <Text white>Consultants</Text>
//     <Text white>Freelancers</Text>
//     <Text white>Gyms</Text>
//   </ClientGrid>
// </Row>
